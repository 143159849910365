import React from "react";
import Cover from "../../components/Cover/Cover";
import "./Home.css";

function Home() {
    return (
        <>
            <Cover />
        </>
    );
}

export default Home;
